html, body {
    margin: 10px 20px 0 20px;
    padding: 0;
}

#navigation {
    margin-top: 5px;
}

#content {
    margin-top: 5px;
    min-height: 150px;
    overflow: hidden;
    padding: 0 15px 15px;
}

#footer {
    margin-top: 0;
    padding: 5px;
}
    #footer .right {
        float: right;
    }

/***** Grid *****/

[class*="col-"] {
    float: left;
    width: 100%;
}

@media only screen and (min-width: 600px) {
    /* For tablets: */
    .col-m-1 {width: 8.33%;}
    .col-m-2 {width: 16.66%;}
    .col-m-3 {width: 25%;}
    .col-m-4 {width: 33.33%;}
    .col-m-5 {width: 41.66%;}
    .col-m-6 {width: 50%;}
    .col-m-7 {width: 58.33%;}
    .col-m-8 {width: 66.66%;}
    .col-m-9 {width: 75%;}
    .col-m-10 {width: 83.33%;}
    .col-m-11 {width: 91.66%;}
    .col-m-12 {width: 100%;}
}

@media only screen and (min-width: 768px) {
    /* For desktop: */
    .col-1 {width: 8.33%;}
    .col-2 {width: 16.66%;}
    .col-3 {width: 25%;}
    .col-4 {width: 33.33%;}
    .col-5 {width: 41.66%;}
    .col-6 {width: 50%;}
    .col-7 {width: 58.33%;}
    .col-8 {width: 66.66%;}
    .col-9 {width: 75%;}
    .col-10 {width: 83.33%;}
    .col-11 {width: 91.66%;}
    .col-12 {width: 100%;}
}

@media only screen and (min-width: 1224px) {
    /* For desktop: */
    .col-hd-1 {width: 8.33%;}
    .col-hd-2 {width: 16.66%;}
    .col-hd-3 {width: 25%;}
    .col-hd-4 {width: 33.33%;}
    .col-hd-5 {width: 41.66%;}
    .col-hd-6 {width: 50%;}
    .col-hd-7 {width: 58.33%;}
    .col-hd-8 {width: 66.66%;}
    .col-hd-9 {width: 75%;}
    .col-hd-10 {width: 83.33%;}
    .col-hd-11 {width: 91.66%;}
    .col-hd-12 {width: 100%;}
}

@media only screen and (min-width: 1824px) {
    /* For desktop: */
    .col-2k-1 {width: 8.33%;}
    .col-2k-2 {width: 16.66%;}
    .col-2k-3 {width: 25%;}
    .col-2k-4 {width: 33.33%;}
    .col-2k-5 {width: 41.66%;}
    .col-2k-6 {width: 50%;}
    .col-2k-7 {width: 58.33%;}
    .col-2k-8 {width: 66.66%;}
    .col-2k-9 {width: 75%;}
    .col-2k-10 {width: 83.33%;}
    .col-2k-11 {width: 91.66%;}
    .col-2k-12 {width: 100%;}
}

.inner {
    margin: 20px;
}

/***** Menu *****/

#menu {
    margin: 0;
    padding: 10px 0 0 0;
    width: 100%;
}

#menu li {
    float: left;
    padding: 0 0 10px 0;
    position: relative;
    line-height: 0;
}

#menu a {
    float: left;
    height: 25px;
    padding: 0 25px;
}

#menu li:hover > ul {
    display: block;
}

/* Sub-menu */

#menu ul {
    display: none;
    left: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 35px;
    z-index: 99999;
}

#menu ul ul {
    top: 0;
    left: 150px;
}

#menu ul li {
    display: block;
    float: none;
    margin: 0;
    padding: 0;
}

#menu ul a {
    display: block;
    float: none;
    height: 10px;
    height: auto;
    min-width: 130px;
    max-width: 180px;
    padding: 10px;
}

*html #menu ul a /* IE6 */  {
    height: 10px;
}

*:first-child+html #menu ul a /* IE7 */  {
    height: 10px;
}

#menu ul li:first-child > a:after {
    content: '';
    position: absolute;
    left: 30px;
    top: -8px;
    width: 0;
    height: 0;
}

#menu ul ul li:first-child a:after {
    left: -8px;
    top: 12px;
    width: 0;
    height: 0;
}

#menu ul li ul li {
  width: 150px;

  a {
    width: 100%;
  }
}

/* Clear floated elements */
#menu:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}

* html #menu             { zoom: 1; } /* IE6 */
*:first-child+html #menu { zoom: 1; } /* IE7 */

